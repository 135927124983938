/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    SubscriptionStatus,
    SubscriptionStatusFromJSON,
    SubscriptionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountAdmin
 */
export interface AccountAdmin {
    /**
     * 
     * @type {string}
     * @memberof AccountAdmin
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAdmin
     */
    basePlanId: string;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AccountAdmin
     */
    subscriptionStatus: SubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof AccountAdmin
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAdmin
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAdmin
     */
    cfJwt: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAdmin
     */
    intercomIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAdmin
     */
    chatwootIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAdmin
     */
    apiKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountAdmin
     */
    isSuperAdmin?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountAdmin
     */
    attrs?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountAdmin
     */
    addons?: { [key: string]: string; };
}

export function AccountAdminFromJSON(json: any): AccountAdmin {
    return {
        'accountId': json['accountId'],
        'basePlanId': json['basePlanId'],
        
        'subscriptionStatus': SubscriptionStatusFromJSON(json['subscriptionStatus']),
        'name': json['name'],
        'email': json['email'],
        'cfJwt': json['cfJwt'],
        'intercomIdentity': !exists(json, 'intercomIdentity') ? undefined : json['intercomIdentity'],
        'chatwootIdentity': !exists(json, 'chatwootIdentity') ? undefined : json['chatwootIdentity'],
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        'isSuperAdmin': !exists(json, 'isSuperAdmin') ? undefined : json['isSuperAdmin'],
        'attrs': !exists(json, 'attrs') ? undefined : json['attrs'],
        'addons': !exists(json, 'addons') ? undefined : json['addons'],
    };
}

export function AccountAdminToJSON(value?: AccountAdmin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'accountId': value.accountId,
        'basePlanId': value.basePlanId,
        
        'subscriptionStatus': SubscriptionStatusToJSON(value.subscriptionStatus),
        'name': value.name,
        'email': value.email,
        'cfJwt': value.cfJwt,
        'intercomIdentity': value.intercomIdentity,
        'chatwootIdentity': value.chatwootIdentity,
        'apiKey': value.apiKey,
        'isSuperAdmin': value.isSuperAdmin,
        'attrs': value.attrs,
        'addons': value.addons,
    };
}


