/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Admin API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    FeaturesTable,
    FeaturesTableFromJSON,
    FeaturesTableToJSON,
    Plan,
    PlanFromJSON,
    PlanToJSON,
} from './';

/**
 * 
 * @export
 * @interface PlansGetResponse
 */
export interface PlansGetResponse {
    /**
     * 
     * @type {Array<Plan>}
     * @memberof PlansGetResponse
     */
    plans: Array<Plan>;
    /**
     * 
     * @type {FeaturesTable}
     * @memberof PlansGetResponse
     */
    featuresTable?: FeaturesTable;
}

export function PlansGetResponseFromJSON(json: any): PlansGetResponse {
    return {
        
        'plans': (json['plans'] as Array<any>).map(PlanFromJSON),
        
        
        'featuresTable': !exists(json, 'featuresTable') ? undefined : FeaturesTableFromJSON(json['featuresTable']),
    };
}

export function PlansGetResponseToJSON(value?: PlansGetResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'plans': (value.plans as Array<any>).map(PlanToJSON),
        
        
        'featuresTable': FeaturesTableToJSON(value.featuresTable),
    };
}


