/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface IdeaSearch
 */
export interface IdeaSearch {
    /**
     * 
     * @type {string}
     * @memberof IdeaSearch
     */
    sortBy?: IdeaSearchSortByEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaSearch
     */
    filterCategoryIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaSearch
     */
    invertCategory?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaSearch
     */
    filterStatusIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaSearch
     */
    invertStatus?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdeaSearch
     */
    filterTagIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaSearch
     */
    invertTag?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdeaSearch
     */
    filterAuthorId?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaSearch
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaSearch
     */
    fundedByMeAndActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IdeaSearch
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof IdeaSearch
     */
    similarToIdeaId?: string;
}

export function IdeaSearchFromJSON(json: any): IdeaSearch {
    return {
        'sortBy': !exists(json, 'sortBy') ? undefined : json['sortBy'],
        'filterCategoryIds': !exists(json, 'filterCategoryIds') ? undefined : json['filterCategoryIds'],
        'invertCategory': !exists(json, 'invertCategory') ? undefined : json['invertCategory'],
        'filterStatusIds': !exists(json, 'filterStatusIds') ? undefined : json['filterStatusIds'],
        'invertStatus': !exists(json, 'invertStatus') ? undefined : json['invertStatus'],
        'filterTagIds': !exists(json, 'filterTagIds') ? undefined : json['filterTagIds'],
        'invertTag': !exists(json, 'invertTag') ? undefined : json['invertTag'],
        'filterAuthorId': !exists(json, 'filterAuthorId') ? undefined : json['filterAuthorId'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'fundedByMeAndActive': !exists(json, 'fundedByMeAndActive') ? undefined : json['fundedByMeAndActive'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'similarToIdeaId': !exists(json, 'similarToIdeaId') ? undefined : json['similarToIdeaId'],
    };
}

export function IdeaSearchToJSON(value?: IdeaSearch): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'sortBy': value.sortBy,
        'filterCategoryIds': value.filterCategoryIds,
        'invertCategory': value.invertCategory,
        'filterStatusIds': value.filterStatusIds,
        'invertStatus': value.invertStatus,
        'filterTagIds': value.filterTagIds,
        'invertTag': value.invertTag,
        'filterAuthorId': value.filterAuthorId,
        'searchText': value.searchText,
        'fundedByMeAndActive': value.fundedByMeAndActive,
        'limit': value.limit,
        'similarToIdeaId': value.similarToIdeaId,
    };
}

/**
* @export
* @enum {string}
*/
export enum IdeaSearchSortByEnum {
    Trending = 'Trending',
    Top = 'Top',
    New = 'New',
    Random = 'Random',
    DragAndDrop = 'DragAndDrop'
}


